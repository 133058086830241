import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import ImageGallery from "../imageGallery"

const LeChateauGallery = props => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "portfolio/le-chateau/gallery" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(
                maxHeight: 600
                maxWidth: 600
                grayscale: true
                quality: 100
              ) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const sortedImages = allFile.edges.sort(function(a, b) {
    return a.node.childImageSharp.fluid.originalName.localeCompare(
      b.node.childImageSharp.fluid.originalName,
      undefined,
      {
        numeric: true,
        sensitivity: "base",
      }
    )
  })

  return (
    <ImageGallery
      title="Le Chateau"
      description="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad obcaecati quo sed? A fugiat id illo illum incidunt laborum molestiae molestias perferendis quaerat sequi tempora tempore, temporibus voluptate. Cum, eveniet."
      images={sortedImages}
      close={props.close}
    />
  )
}

LeChateauGallery.propTypes = {
  close: PropTypes.func,
}

LeChateauGallery.defaultProps = {}

export default LeChateauGallery
