import styled from "styled-components"

const ContentPanelStyle = styled.div`
  z-index: 3;
  position: relative;
  padding-top: 8em;
  padding-bottom: 8em;
  color: #fff;

  .heading {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    h1 {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0.75em;
      font-family: CamphorThin;
    }

    p {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      padding-bottom: 4em;
    }
  }

  p {
    font-weight: 300;
    color: var(--colour-grey-dark);
  }

  @media (min-width: 768px) {
    padding-top: 10em;
    padding-bottom: 10em;

    .heading p {
      max-width: 80%;
    }
  }

  @media (min-width: 992px) {
    .heading h1 {
      max-width: 80%;
    }
    .heading p {
      max-width: 90%;
    }
  }
`

export default ContentPanelStyle
