import React from "react"
import PropTypes from "prop-types"

import ContentPanel from "../content-panel/panel"
import Tiles from "./tiles/tiles"
import ServicesStyle from "./style"
import SightLines from "../sight-lines/lines"

const ServicesPanel = props => {
  return (
    <ServicesStyle className="row">
      <SightLines />

      <div className="col col-no-padding">
        <Tiles skewOffset={props.skewOffset} />

        <ContentPanel>
          <div className="row">
            <div className="col heading">
              <h1>Our Services</h1>
              <p>
                We offer a full range of services to help our clients meet their
                construction challenges. They include a pre-construction review,
                value engineering, estimating and engineering.
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-10 service">
              <div className="content">
                <h3>Metal Framing & Drywall</h3>
                <ul>
                  <li>Interior light gauge metal stud framing</li>
                  <li>Curtain walls</li>
                  <li>Complete drywall assemblies</li>
                  <li>Shaft wall assemblies</li>
                  <li>STC and fire-rated assemblies</li>
                  <li>Abuse resistant assemblies</li>
                  <li>Bulletproof assemblies</li>
                  <li>Radio frequency shielding</li>
                  <li>PC 350 installations</li>
                </ul>

                <h3>Exterior</h3>
                <ul>
                  <li>Exterior Insulation Finish System (EIFS)</li>
                  <li>Wind load bearing structural steel stud systems</li>
                </ul>

                <h3>Interior</h3>
                <ul>
                  <li>Level 1-5 or custom finishing</li>
                  <li>Specialty trims and reveals</li>
                  <li>Venetian plaser and moldings</li>
                  <li>Plaster restoration</li>
                </ul>

                <h3>Acoustical & Specialty Ceilings</h3>
                <ul>
                  <li>Acoustical ceilings</li>
                  <li>Metal ceilings</li>
                  <li>Wood ceilings</li>
                  <li>Clouds and canopies</li>
                  <li>Acoustical wall panels</li>
                </ul>
              </div>
            </div>
          </div>
        </ContentPanel>
      </div>
    </ServicesStyle>
  )
}

ServicesPanel.propTypes = {
  skewOffset: PropTypes.number,
}

export default ServicesPanel
