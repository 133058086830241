import React from "react"
import Img from "gatsby-image"

import ClientsStyle from "./style"
import ContentPanel from "../content-panel/panel"
import { useStaticQuery, graphql } from "gatsby"

const ClientsPanel = () => {
  const images = useStaticQuery(graphql`
    query {
      nespresso: file(relativePath: { eq: "clients/nespresso.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lululemon: file(relativePath: { eq: "clients/lululemon.png" }) {
        childImageSharp {
          fluid(maxWidth: 225, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      apple: file(relativePath: { eq: "clients/apple.png" }) {
        childImageSharp {
          fluid(maxWidth: 50, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      goodlife: file(relativePath: { eq: "clients/goodlife.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      government: file(relativePath: { eq: "clients/government.png" }) {
        childImageSharp {
          fluid(maxWidth: 175, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      rogers: file(relativePath: { eq: "clients/rogers.png" }) {
        childImageSharp {
          fluid(maxWidth: 175, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      saks: file(relativePath: { eq: "clients/saks.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 90, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      madradish: file(relativePath: { eq: "clients/madradish.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 225, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      nordstrom: file(relativePath: { eq: "clients/nordstrom.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      petsmart: file(relativePath: { eq: "clients/petsmart.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zara: file(relativePath: { eq: "clients/zara.png" }) {
        childImageSharp {
          fluid(maxWidth: 175, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cbc: file(relativePath: { eq: "clients/cbc.png" }) {
        childImageSharp {
          fluid(maxWidth: 150, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      gap: file(relativePath: { eq: "clients/gap.png" }) {
        childImageSharp {
          fluid(maxWidth: 60, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bell: file(relativePath: { eq: "clients/bell.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      birks: file(relativePath: { eq: "clients/birks.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 75, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      davidstea: file(relativePath: { eq: "clients/davidstea.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      audi: file(relativePath: { eq: "clients/audi.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      uofottawa: file(relativePath: { eq: "clients/uofottawa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 130, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      via: file(relativePath: { eq: "clients/via.png" }) {
        childImageSharp {
          fluid(maxWidth: 130, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clubmonaco: file(relativePath: { eq: "clients/clubmonaco.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <ClientsStyle className="row">
      <div className="col col-no-padding">
        <ContentPanel>
          <div className="row">
            <div className="col heading">
              <h1>From startups to the world's largest companies</h1>
              <p>
                Hundreds of companies from across Canada have entrusted BJ
                Normand with their commercial construction projects.
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col clients-grid">
              <div className="client">
                <Img
                  style={{ maxWidth: "150px" }}
                  fluid={images.nespresso.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "225px" }}
                  fluid={images.lululemon.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "50px" }}
                  fluid={images.apple.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "200px" }}
                  fluid={images.goodlife.childImageSharp.fluid}
                />
              </div>

              <div className="client">
                <Img
                  style={{ maxWidth: "175px" }}
                  fluid={images.government.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "175px" }}
                  fluid={images.rogers.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "90px" }}
                  fluid={images.saks.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "225px" }}
                  fluid={images.madradish.childImageSharp.fluid}
                />
              </div>

              <div className="client">
                <Img
                  style={{ maxWidth: "200px" }}
                  fluid={images.nordstrom.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "150px" }}
                  fluid={images.petsmart.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "175px" }}
                  fluid={images.zara.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "150px" }}
                  fluid={images.cbc.childImageSharp.fluid}
                />
              </div>

              <div className="client">
                <Img
                  style={{ maxWidth: "60px" }}
                  fluid={images.gap.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "100px" }}
                  fluid={images.bell.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "75px" }}
                  fluid={images.birks.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "100px" }}
                  fluid={images.davidstea.childImageSharp.fluid}
                />
              </div>

              <div className="client">
                <Img
                  style={{ maxWidth: "120px" }}
                  fluid={images.audi.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "130px" }}
                  fluid={images.uofottawa.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "130px" }}
                  fluid={images.via.childImageSharp.fluid}
                />
              </div>
              <div className="client">
                <Img
                  style={{ maxWidth: "200px" }}
                  fluid={images.clubmonaco.childImageSharp.fluid}
                />
              </div>
            </div>
          </div>
        </ContentPanel>
      </div>
    </ClientsStyle>
  )
}

ClientsPanel.propTypes = {}

ClientsPanel.defaultProps = {}

export default ClientsPanel
