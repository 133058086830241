import styled, { css } from 'styled-components'

const visibleStyle = `
  height: auto;
  opacity: 1;
`;

const hiddenStyle = `
  position: absolute;
  left: -99999px;
  height: 0;
  opacity: 0;
`;

const PortfolioItemStyle = styled.div`
  cursor: pointer;    

  ${props => props.isVisible ? css`${ visibleStyle }` : css`${ hiddenStyle }`}

  :hover {
    .portfolio-item {
      &:before {
        background: rgba(33, 37, 41, 0.9);
      }

      span:after {
        width: 100%;
        left: 0;
      }
    }
  }

  .portfolio-item {
    position: relative;
    padding: 0;
    
    :before {
      z-index: 1;
      content: '';
      position: relative;
      padding-top: 100%;
      display: block;
      background: rgba(33, 37, 41, 0.6);
      transition: background 0.2s ease-in-out;
    } 
    
    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
      span {
        z-index: 2;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      span:after {
        content: "";
        position: absolute;
        width: 0;        
        height: 4px;
        bottom: 0;
        left: 50%;
        display: block;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        background: none repeat scroll 0 0 transparent;
        background-color: var(--colour-red);
      }
    }
    
    .gatsby-image-wrapper {
      position: absolute !important;
      height: 100%;
      width: 100%;
    }
  }
`

export default PortfolioItemStyle