import React from "react"
import PropTypes from "prop-types"

import { CLIENTS } from "../constants"
import AudiGallery from "./galleries/audi"
import CarletonGallery from "./galleries/carleton"
import OCTranspoGallery from "./galleries/octranspo"
import TangerGallery from "./galleries/tanger"
import StructubeGallery from "./galleries/structube"
import PPSGallery from "./galleries/pps"
import HawthorneGallery from "./galleries/hawthorne"
import BruyereGallery from "./galleries/bruyere"
import MarchNetworksGallery from "./galleries/marchNetworks"
import LeChateauGallery from "./galleries/leChateau"
import NespressoBayshoreGallery from "./galleries/nespressoBayshore"

class GalleryLoader extends React.Component {
  state = {
    gallery: null,
  }

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].classList.remove("modal-open")
  }

  componentDidMount() {
    document.getElementsByTagName("body")[0].classList.add("modal-open")

    switch (this.props.client) {
      case CLIENTS.AUDI:
        this.setState({ gallery: <AudiGallery close={this.props.close} /> })
        break
      case CLIENTS.CARLETON:
        this.setState({ gallery: <CarletonGallery close={this.props.close} /> })
        break
      case CLIENTS.OCTRANSPO:
        this.setState({
          gallery: <OCTranspoGallery close={this.props.close} />,
        })
        break
      case CLIENTS.TANGER_OUTLETS:
        this.setState({
          gallery: <TangerGallery close={this.props.close} />,
        })
        break
      case CLIENTS.STRUCTUBE:
        this.setState({
          gallery: <StructubeGallery close={this.props.close} />,
        })
        break
      case CLIENTS.PPS:
        this.setState({
          gallery: <PPSGallery close={this.props.close} />,
        })
        break
      case CLIENTS.HAWTHORNE:
        this.setState({
          gallery: <HawthorneGallery close={this.props.close} />,
        })
        break

      case CLIENTS.BRUYERE:
        this.setState({
          gallery: <BruyereGallery close={this.props.close} />,
        })
        break
      case CLIENTS.MARCH_NETWORKS:
        this.setState({
          gallery: <MarchNetworksGallery close={this.props.close} />,
        })
        break
      case CLIENTS.LE_CHATEAU:
        this.setState({
          gallery: <LeChateauGallery close={this.props.close} />,
        })
        break
      case CLIENTS.NESPRESSO:
        this.setState({
          gallery: <NespressoBayshoreGallery close={this.props.close} />
        })
        break
    }
  }

  render() {
    return this.state.gallery
  }
}

GalleryLoader.propTypes = {
  client: PropTypes.string,
  close: PropTypes.func,
}

GalleryLoader.defaultProps = {}

export default GalleryLoader
