import styled from "styled-components"

const ImageGalleryStyle = styled.div`
  .modal {
    background: var(--colour-white-dark);
    .modal-content {
      // background: var(--colour-white-dark);
    }
  }

  button.close {
    z-index: 1051;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 5px;
    right: ${props => props.scrollbarWidth + 15 + "px"};
    padding: 16px 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 1;
    color: rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.05);
    border: none;
    appearance: none;
    cursor: pointer;
    transition: all 0.3s;

    :hover {
      color: #fff;
      background-color: var(--colour-red);
    }

    span {
      display: inline-block;
      transform: translateY(-5px);
    }
  }

  h2 {
    color: var(--colour-red);
    margin-bottom: 1em;
  }

  p {
    max-width: 750px;
    margin-right: 37px;
    padding-right: 15px;
    color: var(--colour-grey-dark);
  }

  .gatsby-image-wrapper {
    //margin: 0 15px 15px 15px;
  }

  .modal-body {
    padding: 0 1em;

    .gatsby-image-wrapper {
      max-height: 700px;
      //max-width: 600px;
    }

    @media only screen and (min-width: 768px) {
      .col-md-6:nth-child(odd) {
        padding-right: 0.5em;
      }
      .col-md-6:nth-of-type(even) {
        padding-left: 0.5em;
      }
    }
  }
`

export default ImageGalleryStyle
