import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import ImageGallery from "../imageGallery"

const NespressoBayshoreGallery = props => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativeDirectory: { eq: "portfolio/nespresso-bayshore" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(
                maxHeight: 600
                maxWidth: 600
                grayscale: true
                quality: 100
              ) {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const sortedImages = allFile.edges.sort(function(a, b) {
    return a.node.childImageSharp.fluid.originalName.localeCompare(
      b.node.childImageSharp.fluid.originalName,
      undefined,
      {
        numeric: true,
        sensitivity: "base",
      }
    )
  })

  return (
    <ImageGallery
      title="Nespresso Bayshore"
      description=""
      images={sortedImages}
      close={props.close}
    />
  )
}

NespressoBayshoreGallery.propTypes = {
  close: PropTypes.func,
}

NespressoBayshoreGallery.defaultProps = {}

export default NespressoBayshoreGallery
