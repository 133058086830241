import styled from 'styled-components'

const TileStyle = styled.div`  
  .tile-container,
  .background-container {
    height: 100%;
    
      .grid {
      height: 100%;
    }
  }

  .background-container {
    z-index: 2;
    top: -${props => props.skewOffset}px;
    background: var(--colour-white-dark);
  }
  
  .tile-container {
    z-index: 3 !important;
    top: -${props => props.skewOffset + 1}px;

    .grid {
      .tile:first-child {
        grid-row: 1;
        grid-column: span 7/viewport-end;
        background-color: #000;
        opacity: 0.1;
      }
      
      .tile:nth-child(2) {
        grid-row: 2;
        grid-column: -9/span 2;
        background-color: var(--colour-red);
      }

      .tile:nth-child(3) {
        grid-row: 2;
        grid-column: -7/span 2;
        background-color: var(--colour-maroon)
      }

      .tile:nth-child(4) {
        grid-row: 4;
        grid-column: -6/span 2;
        box-shadow: inset 0 0 0 1.5px var(--colour-grey-dark);
        opacity: 0.3;
      }                       
    }
  }
`

export default TileStyle