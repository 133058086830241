import React from "react"
import styled from "styled-components"

import logo from "../../images/logo.svg"
import HeaderStyle from "./style"
import ContentPanel from "../content-panel/panel"

const Logo = styled.div`
  width: 175px;
  color: var(--colour-white-dark);
  //color: rgba(255, 255, 255, 0.75);
`
const shapeRenderingType = "geometricPrecision"

const Header = () => (
  <HeaderStyle id="header-container" className="row">
    <div className="col col-no-padding align-self-center">
      <ContentPanel>
        <Logo>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 330.9311 76.3197"
          >
            <g id="black-rgb" fill="currentColor">
              <g id="logoMark">
                <path
                  id="logoMark_PathItem_"
                  data-name="logoMark &lt;PathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M78.6052,16.4763,60.4022,1.75A7.8612,7.8612,0,0,0,52.2261.6952L27.5674,11.82,49.3879,29.659Z"
                />
                <path
                  id="logoMark_PathItem_2"
                  data-name="logoMark &lt;PathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M50.7433,39.1485a5.1364,5.1364,0,0,0-5.3449-.6893l-11.457,5.1684,10.7642,8.8,10.0251-4.5226a2.9659,2.9659,0,0,0,.6458-5.0092Z"
                />
                <path
                  id="logoMark_PathItem_3"
                  data-name="logoMark &lt;PathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M40.4947,34.7837a2.9655,2.9655,0,0,0,.6577-4.9989L36.56,26.03a5.137,5.137,0,0,0-5.3663-.7057L19.6805,30.5179l10.837,8.7671Z"
                />
                <path
                  id="logoMark_PathItem_4"
                  data-name="logoMark &lt;PathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M0,60.0679,17.2421,74.1644a9.5456,9.5456,0,0,0,9.9677,1.31L51.0377,64.7242,29.0708,46.9529Z"
                />
              </g>
              <g id="type">
                <path
                  id="type_CompoundPathItem_"
                  data-name="type &lt;CompoundPathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M91.6179,51.2989h-6.644a.6308.6308,0,0,1-.6307-.6307V45.9208a.6308.6308,0,0,1,.6307-.6307h6.644c2.174,0,3.36,1.3441,3.36,3.004,0,1.8982-1.2649,3.0048-3.36,3.0048M84.3432,35.4447a.6308.6308,0,0,1,.6307-.6307H91.38A2.7712,2.7712,0,0,1,94.4635,37.58c0,1.7008-1.1858,2.7683-3.0832,2.7683H84.9739a.6308.6308,0,0,1-.6307-.6307ZM95.65,42.641a5.8873,5.8873,0,0,0,4.5456-6.0488c0-3.5182-2.569-6.72-7.5905-6.72H79.9916A1.2615,1.2615,0,0,0,78.73,31.134V54.979A1.2615,1.2615,0,0,0,79.9916,56.24H93.0411c5.06,0,7.67-3.1623,7.67-7.1554A6.3413,6.3413,0,0,0,95.65,42.641"
                />
                <path
                  id="type_PathItem_"
                  data-name="type &lt;PathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M118.6176,29.8723h-3.761a.9458.9458,0,0,0-.9455.9465V47.5039a3.9335,3.9335,0,0,1-4.1115,4.23,5.494,5.494,0,0,1-3.3722-1.1946.942.942,0,0,0-1.3851.2838l-1.5,2.6125a.9584.9584,0,0,0,.2384,1.224,10.1647,10.1647,0,0,0,6.4927,2.0558c5.3764,0,9.29-2.9248,9.29-9.1321V30.8188a.946.946,0,0,0-.9465-.9465"
                />
                <path
                  id="type_PathItem_2"
                  data-name="type &lt;PathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M156.5206,29.874H152.8a.9466.9466,0,0,0-.9465.9465V46.4381l-11.9323-16.18a.9447.9447,0,0,0-.7614-.3843h-4.3472a.9461.9461,0,0,0-.9465.9465V55.2962a.9467.9467,0,0,0,.9465.9465h3.721a.9467.9467,0,0,0,.9464-.9465V39.0452l12.2881,16.81a.9458.9458,0,0,0,.7641.3879h3.9887a.9467.9467,0,0,0,.9465-.9465V30.8205a.946.946,0,0,0-.9465-.9465"
                />
                <path
                  id="type_CompoundPathItem_2"
                  data-name="type &lt;CompoundPathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M175.1614,51.7357c-4.9022,0-8.0254-3.7556-8.0254-8.657,0-4.9423,3.1232-8.6588,8.0254-8.6588,4.8623,0,7.9854,3.7165,7.9854,8.6588,0,4.9014-3.1231,8.657-7.9854,8.657m0-22.2963c-7.9854,0-13.7976,5.6922-13.7976,13.6393,0,7.9454,5.8122,13.6384,13.7976,13.6384,7.9463,0,13.7577-5.693,13.7577-13.6384,0-7.9471-5.8114-13.6393-13.7577-13.6393"
                />
                <path
                  id="type_CompoundPathItem_3"
                  data-name="type &lt;CompoundPathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M204.2138,41.9322h-4.9832a.9467.9467,0,0,1-.9465-.9465V35.7614a.9467.9467,0,0,1,.9465-.9464h4.9832c2.2141,0,3.9149,1.345,3.9149,3.519,0,2.2532-1.7008,3.5982-3.9149,3.5982M213.9,38.3731c0-4.9022-3.4-8.4986-8.8945-8.4986H193.6167a.9457.9457,0,0,0-.9456.9456V55.2957a.9459.9459,0,0,0,.9456.9465h3.721a.946.946,0,0,0,.9464-.9465v-8.423h4.1115l4.9094,8.8812a.9456.9456,0,0,0,.8281.4883h4.2618a.9457.9457,0,0,0,.813-1.4295l-5.1193-8.6116a7.6278,7.6278,0,0,0,5.8114-7.828"
                />
                <path
                  id="type_PathItem_3"
                  data-name="type &lt;PathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M245.3282,29.874h-6.3131a.9463.9463,0,0,0-.8824.6031l-6.17,15.8819-6.1689-15.8819a.9478.9478,0,0,0-.8825-.6031h-6.273a.9461.9461,0,0,0-.9465.9465V55.2962a.9467.9467,0,0,0,.9465.9465h3.721a.9467.9467,0,0,0,.9464-.9465V37.227L230.5022,55.64a.9474.9474,0,0,0,.8815.6023h1.1591a.9474.9474,0,0,0,.8815-.6023l7.1964-18.4134V55.2962a.9467.9467,0,0,0,.9465.9465h3.761a.9467.9467,0,0,0,.9464-.9465V30.8205a.946.946,0,0,0-.9464-.9465"
                />
                <path
                  id="type_CompoundPathItem_4"
                  data-name="type &lt;CompoundPathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M265.7572,46.8335h-6.4243a.6311.6311,0,0,1-.5933-.8459l2.915-8.045a.9468.9468,0,0,1,1.78,0l2.915,8.045a.63.63,0,0,1-.5924.8459m.7143-15.9005a1.654,1.654,0,0,0-1.5425-1.0594h-4.7679a1.6532,1.6532,0,0,0-1.5425,1.0594l-8.8865,23.0613a1.6536,1.6536,0,0,0,1.5434,2.2479h2.8065a1.6541,1.6541,0,0,0,1.55-1.0772l1.26-3.39h11.3069l1.26,3.39a1.6543,1.6543,0,0,0,1.55,1.0772h2.8065a1.6531,1.6531,0,0,0,1.5425-2.2479Z"
                />
                <path
                  id="type_CompoundPathItem_5"
                  data-name="type &lt;CompoundPathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M316.936,51.3013h-3.8375a.9467.9467,0,0,1-.9465-.9465V35.7619a.946.946,0,0,1,.9465-.9465h3.8766c5.3773,0,8.2229,3.5982,8.2229,8.2229a7.9025,7.9025,0,0,1-8.262,8.263m0-21.4273h-9.4514a.946.946,0,0,0-.9465.9465V55.2962a.9467.9467,0,0,0,.9465.9465h9.49c8.2229,0,13.956-5.219,13.956-13.2044,0-7.9062-5.7331-13.1643-13.9951-13.1643"
                />
                <path
                  id="type_PathItem_4"
                  data-name="type &lt;PathItem&gt;"
                  shape-rendering={shapeRenderingType}
                  d="M301.1688,29.874h-3.721a.9466.9466,0,0,0-.9464.9465V46.4381l-11.9323-16.18a.9448.9448,0,0,0-.7615-.3843H279.46a.946.946,0,0,0-.9464.9465V55.2962a.9467.9467,0,0,0,.9464.9465h3.721a.9467.9467,0,0,0,.9465-.9465V39.0452l12.2881,16.81a.9452.9452,0,0,0,.7632.3879h3.99a.9467.9467,0,0,0,.9465-.9465V30.8205a.9461.9461,0,0,0-.9465-.9465"
                />
              </g>
            </g>
          </svg>
        </Logo>
      </ContentPanel>
    </div>
  </HeaderStyle>
)

Header.propTypes = {}

Header.defaultProps = {}

export default Header
