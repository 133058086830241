import React from "react"
import PropTypes from 'prop-types';

import TileStyle from './style';

const Tiles = (props) => {

  return (
    <TileStyle className="tile-wrapper" skewOffset={ props.skewOffset }>
      <div className="background-container">
        <div className="grid">
          <div className="background" />
        </div>
      </div>

      <div className="tile-container">
        <div className="grid">
          <div className="tile" />
          <div className="tile" />
          <div className="tile" />
          <div className="tile" />
        </div>
      </div>
    </TileStyle>
  )
}


Tiles.propTypes = {
  skewOffset: PropTypes.number
}

Tiles.defaultProps = {}

export default Tiles

