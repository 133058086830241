import styled from "styled-components"

const HeaderStyle = styled.header`
  height: 75px;
  background: var(--colour-slate);

  .container {
    padding-top: 0;
    padding-bottom: 0;
    line-height: 0;
  }
`

export default HeaderStyle
