import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import PortfolioStyle from "./style"
import Tiles from "./tiles/tiles"
import ContentPanel from "../content-panel/panel"
import PortfolioItem from "./item/item"
import TypeSelector from "./type-selector/typeSelector"
import GalleryLoader from "./image-gallery/galleryLoader"

import { PORTFOLIO_TYPE, CLIENTS } from "./constants"
import SightLines from "../sight-lines/lines"

const initialState = {
  isSectorVisible: {
    RETAIL: true,
    GOVERNMENT: true,
    INSTITUTIONAL: true,
    GENERAL_CONTRACTOR: true,
  },
  areAllProjectsVisible: true,
  openGallery: null,
}

class PortfolioPanel extends React.Component {
  state = initialState

  render() {
    const toggleVisibility = toggleType => {
      let newState = Object.assign({}, this.state)

      Object.keys(PORTFOLIO_TYPE).forEach(type => {
        if (toggleType === type) {
          newState = {
            isSectorVisible: {
              ...newState.isSectorVisible,
              [type]: true,
            },
            areAllProjectsVisible: false,
          }
        } else {
          newState = {
            isSectorVisible: {
              ...newState.isSectorVisible,
              [type]: false,
            },
            areAllProjectsVisible: false,
          }
        }
      })

      this.setState(newState)
    }

    const showAllProjects = () => {
      this.setState(initialState)
    }

    const loadGallery = type => {
      this.setState({ openGallery: type })
    }

    const closeGallery = () => this.setState({ openGallery: null })

    return (
      <StaticQuery
        query={graphql`
          query {
            audiBackground: file(
              relativePath: { eq: "portfolio/audi/portfolio-button.png" }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            carletonBackground: file(
              relativePath: { eq: "portfolio/carleton/02.jpg" }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            ocTranspoBackground: file(
              relativePath: {
                eq: "portfolio/oc-transpo/Rockfon OC Transpo-9876-web.jpg"
              }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tangerBackground: file(
              relativePath: {
                eq: "portfolio/tanger/20150728_061022_Campeau Dr_resized.jpg"
              }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            structubeBackground: file(
              relativePath: { eq: "portfolio/structube/20150227_085527.jpg" }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            ppsBackground: file(
              relativePath: { eq: "portfolio/pps/DSC_0698.JPG" }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            hawthorneBackground: file(
              relativePath: { eq: "portfolio/hawthorne/32.jpg" }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            leChateauBackground: file(
              relativePath: { eq: "portfolio/le-chateau/BJN Snaps 094.jpg" }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            bruyereBackground: file(
              relativePath: {
                eq: "portfolio/bruyere/Elizabeth Bruyere 116.jpg"
              }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            marchNetworksBackground: file(
              relativePath: { eq: "portfolio/march-networks/BJN Snaps 123.jpg" }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            nespressoBackground: file(
              relativePath: { eq: "portfolio/nespresso-bayshore/image00010.jpeg" }
            ) {
              childImageSharp {
                fluid(quality: 100, maxWidth: 285, grayscale: true) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            {this.state.openGallery && (
              <GalleryLoader
                client={this.state.openGallery}
                close={closeGallery}
              />
            )}

            <PortfolioStyle className="row">
              <div className="background"></div>

              <SightLines />

              <div className="col col-no-padding">
                <Tiles skewOffset={this.props.skewOffset} />

                <ContentPanel>
                  <div className="row">
                    <div className="col heading">
                      <h1>Commercial Portfolio</h1>
                      <p></p>
                    </div>
                  </div>

                  <div className="row">
                    <TypeSelector
                      label="All"
                      onClick={showAllProjects}
                      isActive={this.state.areAllProjectsVisible}
                    />
                    <TypeSelector
                      type={PORTFOLIO_TYPE.RETAIL.KEY}
                      label={PORTFOLIO_TYPE.RETAIL.LABEL}
                      onClick={toggleVisibility}
                      isActive={
                        this.state.isSectorVisible.RETAIL &&
                        !this.state.areAllProjectsVisible
                      }
                    />
                    <TypeSelector
                      type={PORTFOLIO_TYPE.GOVERNMENT.KEY}
                      label={PORTFOLIO_TYPE.GOVERNMENT.LABEL}
                      onClick={toggleVisibility}
                      isActive={
                        this.state.isSectorVisible.GOVERNMENT &&
                        !this.state.areAllProjectsVisible
                      }
                    />
                    <TypeSelector
                      type={PORTFOLIO_TYPE.INSTITUTIONAL.KEY}
                      label={PORTFOLIO_TYPE.INSTITUTIONAL.LABEL}
                      onClick={toggleVisibility}
                      isActive={
                        this.state.isSectorVisible.INSTITUTIONAL &&
                        !this.state.areAllProjectsVisible
                      }
                    />
                  </div>

                  <div className="row text-center text-uppercase text-camphor-bold item-wrapper">
                    <PortfolioItem
                      title="Nespresso"
                      isVisible={this.state.isSectorVisible.RETAIL}
                      onClick={() => loadGallery(CLIENTS.NESPRESSO)}
                      background={data.nespressoBackground.childImageSharp.fluid}
                    />
                    <PortfolioItem
                      title="Audi"
                      isVisible={this.state.isSectorVisible.RETAIL}
                      onClick={() => loadGallery(CLIENTS.AUDI)}
                      background={data.audiBackground.childImageSharp.fluid}
                    />
                    <PortfolioItem
                      title="Carleton University"
                      isVisible={this.state.isSectorVisible.INSTITUTIONAL}
                      onClick={() => loadGallery(CLIENTS.CARLETON)}
                      background={data.carletonBackground.childImageSharp.fluid}
                    />
                    <PortfolioItem
                      title="OC Transpo"
                      isVisible={this.state.isSectorVisible.GOVERNMENT}
                      onClick={() => loadGallery(CLIENTS.OCTRANSPO)}
                      background={
                        data.ocTranspoBackground.childImageSharp.fluid
                      }
                    />
                    <PortfolioItem
                      title="Tanger Outlets"
                      isVisible={this.state.isSectorVisible.RETAIL}
                      onClick={() => loadGallery(CLIENTS.TANGER_OUTLETS)}
                      background={data.tangerBackground.childImageSharp.fluid}
                    />
                    <PortfolioItem
                      title="Structube"
                      isVisible={this.state.isSectorVisible.RETAIL}
                      onClick={() => loadGallery(CLIENTS.STRUCTUBE)}
                      background={
                        data.structubeBackground.childImageSharp.fluid
                      }
                    />
                    <PortfolioItem
                      title="Parlimentary Protective Services"
                      isVisible={this.state.isSectorVisible.GOVERNMENT}
                      onClick={() => loadGallery(CLIENTS.PPS)}
                      background={data.ppsBackground.childImageSharp.fluid}
                    />
                    <PortfolioItem
                      title="3020 Hawthorne"
                      isVisible={this.state.isSectorVisible.GOVERNMENT}
                      onClick={() => loadGallery(CLIENTS.HAWTHORNE)}
                      background={
                        data.hawthorneBackground.childImageSharp.fluid
                      }
                    />
                    <PortfolioItem
                      title="Le Chateau"
                      isVisible={this.state.isSectorVisible.RETAIL}
                      onClick={() => loadGallery(CLIENTS.LE_CHATEAU)}
                      background={
                        data.leChateauBackground.childImageSharp.fluid
                      }
                    />
                    {/*
                    <PortfolioItem
                      title="Élisabeth Bruyère Hospital"
                      isVisible={this.state.isSectorVisible.INSTITUTIONAL}
                      onClick={() => loadGallery(CLIENTS.BRUYERE)}
                      background={data.bruyereBackground.childImageSharp.fluid}
                    />*/}
                    <PortfolioItem
                      title="March Networks"
                      isVisible={this.state.isSectorVisible.INSTITUTIONAL}
                      onClick={() => loadGallery(CLIENTS.MARCH_NETWORKS)}
                      background={
                        data.marchNetworksBackground.childImageSharp.fluid
                      }
                    />
                  </div>
                </ContentPanel>
              </div>
            </PortfolioStyle>
          </React.Fragment>
        )}
      />
    )
  }
}

PortfolioPanel.propTypes = {
  skewOffset: PropTypes.number,
}

export default PortfolioPanel
