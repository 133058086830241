import styled from "styled-components"

const AssociationsStyle = styled.div`
  --line-colour: rgba(0, 0, 0, 0.05);
  position: relative;

  .heading {
    h1 {
      color: #000;
      text-align: center;

      @media (min-width: 768px) {
        margin-left: 0;
        text-align: left;
      }
    }

    p {
      padding-bottom: 4em;
      @media (min-width: 768px) {
        margin-left: 0;
        padding-bottom: 0; // Remove vertical spacing so vertical center is true
      }
    }
  }

  .image-wrapper {
    margin: 0 auto;
  }
`

export default AssociationsStyle
