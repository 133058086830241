import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import SplashPanel from "../components/splash/splash"
import AboutPanel from "../components/about/about"
import ServicesPanel from "../components/services/services"
import PortfolioPanel from "../components/portfolio/portfolio"
import AssociationsPanel from "../components/associations/associations"
import ClientsPanel from "../components/clients/clients"
import TeamPanel from "../components/team/team"
import Map from "../components/map/map"
import Footer from "../components/footer/footer"

class IndexPage extends React.Component {
  state = {
    skewOffset: null,
  }

  componentDidMount() {
    const calculateSplashPanelSkewOffset = function() {
      const degrees = 12
      const radians = (degrees * Math.PI) / 180
      const newHeight = window.innerWidth * Math.tan(radians)
      const newOffset = newHeight / 2

      return newOffset
    }

    this.setState({
      skewOffset: calculateSplashPanelSkewOffset(),
    })
  }

  render() {
    return (
      <>
        <SEO
          title="sdsdf"
          keywords={[
            `BJN`,
            `BJ Normand`,
            `BJ Normand Ltd`,
            `Drywall`,
            `Steel-stud`,
            `Construction`,
            `Commercial construction`,
          ]}
        />

        <Layout>
          <Header />

          <SplashPanel skewOffset={this.state.skewOffset} />

          <AboutPanel skewOffset={this.state.skewOffset} />

          <ServicesPanel skewOffset={this.state.skewOffset} />

          <PortfolioPanel skewOffset={this.state.skewOffset} />

          <AssociationsPanel />

          <TeamPanel />

          <ClientsPanel />

          <Footer />
        </Layout>
      </>
    )
  }
}

export default IndexPage
