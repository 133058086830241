import styled from "styled-components"

const SplashStyle = styled.div`
  position: relative;
  font-size: 1rem;

  :after {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.7;
  }

  .container {
    z-index: 2;
    position: relative;
    margin-bottom: 8em;
    padding-top: 10em;

    .heading {
      h1 {
        max-width: 90%;
      }

      p {
        margin-bottom: 13em;
        color: var(--colour-grey-light);
        font-family: CamphorThin;
        font-size: 1.2em;
      }
    }
  }

  .gatsby-image-wrapper {
    z-index: 1;
    position: absolute !important;
    height: 100%;
    width: 100%;

    animation-name: backgroundFade;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 40s;

    :nth-of-type(2) {
      animation-delay: 30s;
    }
    :nth-of-type(3) {
      animation-delay: 20s;
    }
    :nth-of-type(4) {
      animation-delay: 10s;
    }
    :nth-of-type(5) {
      animation-delay: 0;
    }
  }

  @keyframes backgroundFade {
    0% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    92% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export default SplashStyle
