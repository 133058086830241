import styled from 'styled-components'

const TileStyle = styled.div`

    .tile-container,
    .background-container {
      height: 100%;
    }
    
    .tile-container {
      top: -${props => props.skewOffset}px;
      
      .grid {
        .tile:first-child {
          grid-row: -10;
          grid-column: left-gutter-start/span 3;
          box-shadow: inset 0 0 0 1.5px var(--colour-red);
        }
        .tile:nth-child(2) {
          grid-row: -9;
          grid-column: 4/span 2;
          background-color: var(--colour-maroon);
          margin-top: -2px;
        }
        .tile:nth-child(3) {
          grid-row: -6;
          grid-column: span 2/right-gutter-end;
          background-color: var(--colour-maroon);
        }
        .tile:nth-child(4) {
          grid-row: -5;
          grid-column: right-gutter-start/span 2;
          box-shadow: inset 0 0 0 1.5px var(--colour-red);   
        }
        .tile:nth-child(5) {
          grid-row: -4;
          grid-column: left-gutter-start/span 3;
          background-color: var(--colour-maroon);      
        }
        .tile:nth-child(6) {
          grid-row: -3;
          grid-column: viewport-start/span 6;
          background-color: var(--colour-grey);      
        }
        .tile:nth-child(7) {
          grid-row: -2;
          grid-column: viewport-start/span 3;
          background-color: var(--colour-grey-light);      
        }
        .tile:nth-child(8) {
          grid-row: -2;
          grid-column: 6/span 3;
          background-color: var(--colour-red);
        }
        .tile:nth-child(9) {
          grid-row: -2;
          grid-column: span 4/right-gutter-end;
          background-color: var(--colour-red);
        }
                       
      }
    }
`

export default TileStyle