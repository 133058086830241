import React from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Map as GoogleMap, Marker, GoogleApiWrapper } from 'google-maps-react';

import MapStyle from './style'

const Map = (props) => {
  const images = useStaticQuery(graphql`
    query {
      mapPin: file(relativePath: { eq: "map-pin.png" }) {
        childImageSharp {
          fixed(width: 27, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const mapStyle = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#212529"
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#212529"
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "administrative.country",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e"
        }
      ]
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "administrative.locality",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd"
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#181818"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#1b1b1b"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "color": "#2c2c2c"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#8a8a8a"
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#373737"
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#3c3c3c"
        }
      ]
    },
    {
      "featureType": "road.highway.controlled_access",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#4e4e4e"
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161"
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#000000"
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#3d3d3d"
        }
      ]
    },
    {
      "featureType": "administrative.neighborhood",
      "elementType": "labels",
      "stylers": [
        { "visibility": "off" }
      ]
    }
  ];

  const redirectToGoggleMaps = () => {
    const BJN_GOOGLE_MAP_BUSINESS = 'https://www.google.com/maps/place/B+J+Normand+Ltd+(Since+1948)/@45.3824257,-75.6060803,15z/data=!4m5!3m4!1s0x0:0x2959ff9fabe8cd47!8m2!3d45.3824257!4d-75.6060803';
    
    window.open(BJN_GOOGLE_MAP_BUSINESS, '_blank');
  };

  return (
    <MapStyle>
      <GoogleMap className="google-map"
        google={ props.google }
        zoom={ 15 }
        zoomControl={ false }
        gestureHandling={ 'false' }
        draggable={ true }
        scrollwheel={ false }
        panControl={ false }
        controls={ false }
        disableDefaultUI={ true }
        initialCenter={{ lat: 45.3824257, lng: -75.6060803 }}
        style={{ width: '100%', height: '400px'}}
        styles={ mapStyle }
        onClick={ redirectToGoggleMaps }
      >
        <Marker
          name={'Office location'}
          position={{ lat: 45.3824257, lng: -75.6060803 }}
          icon={{ url: images.mapPin.childImageSharp.fixed.src }}
        />
      </GoogleMap>                    
    </MapStyle>
  )
};


Map.propTypes = {};

Map.defaultProps = {};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBQeXemuP4Mx_c5zNiBHJMOjXlJjVgZujM'
})(Map);

