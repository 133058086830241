import styled from 'styled-components'

const TileStyle = styled.div`

    .tile-container,
    .background-container {
      height: 100%;
    }

    .gatsby-image-wrapper {
      position: absolute;
      top: 75px;
      left: 0;
      width: 100%;
      bottom: 0;      
    }
    
    .tile-container {
      top: -${props => props.skewOffset}px;
      
      .grid {
        .tile:first-child {
          grid-row: -10;
          grid-column: 3/span 3;
          box-shadow: inset 0 0 0 1.5px var(--colour-grey-light);
        }
        .tile:nth-child(2) {
          grid-row: -9;
          grid-column: 5/span 2;
          background-color: rgba(143, 1, 49, 0.8);
          margin-top: -2px;
        }
        .tile:nth-child(3) {
          grid-row: -6;
          grid-column: span 2/right-gutter-end;
          background-color: var(--colour-red);
        }
        .tile:nth-child(4) {
          grid-row: -5;
          grid-column: right-gutter-start/span 2;
          box-shadow: inset 0 0 0 1.5px #fff;
          opacity: 0.6;
        }
        .tile:nth-child(5) {
          grid-row: -4;
          grid-column: left-gutter-start/span 3;
          background-color: var(--colour-maroon);      
        }
        .tile:nth-child(6) {
          grid-row: -3;
          grid-column: viewport-start/span 6;
          background-color: var(--colour-grey);      
        }
        .tile:nth-child(7) {
          grid-row: -2;
          grid-column: viewport-start/span 3;
          background-color: var(--colour-grey-light);      
        }
        .tile:nth-child(8) {
          grid-row: -2;
          grid-column: 6/span 3;
          background-color: var(--colour-red);
        }
        .tile:nth-child(9) {
          grid-row: -2;
          grid-column: span 3/-3;
          background-color: #984947;
        }
                       
      }
    }
`

export default TileStyle