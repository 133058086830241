import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import ImageGalleryStyle from "./style"

class ImageGallery extends React.Component {
  state = {
    scrollbarWidth: null,
  }

  componentDidMount() {
    const modal = document.getElementById("project-preview")
    this.setState({ scrollbarWidth: modal.offsetWidth - modal.clientWidth })
  }

  render() {
    return (
      <ImageGalleryStyle scrollbarWidth={this.state.scrollbarWidth}>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => this.props.close()}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        <div
          className="modal fade show"
          id="project-preview"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">{this.props.title}</h2>
                {/* <p>{this.props.description}</p> */}
                <p></p>
              </div>

              <div className="row no-gutters modal-body">
                {Object.keys(this.props.images).map(key => (
                  <>
                    <div className="col-12 pb-3" key={key}>
                      <Img
                        key={key}
                        fluid={
                          this.props.images[key]["node"]["childImageSharp"][
                            "fluid"
                          ]
                        }
                        imgStyle={{
                          objectPosition: "top center",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </ImageGalleryStyle>
    )
  }
}

ImageGallery.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.array,
  close: PropTypes.func,
}

ImageGallery.defaultProps = {}

export default ImageGallery
