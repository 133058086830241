import styled from "styled-components"

const TeamMemberStyle = styled.div`
  color: #fff !important;

  .image-wrapper {
    max-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    @media (min-width: 576px) {
      max-width: 200px;
    }

    .gatsby-image-wrapper {
      width: 100%;
    }

    img {
      border-radius: 100%;
      border: 4px solid rgba(255, 255, 255, 0.02);
    }
  }

  .member-details {
    font-size: 16px;

    strong {
      font-family: CamphorBold;
      font-weight: 300;
    }

    span {
      display: block;
    }

    a {
      color: #fff;
      text-decoration: none;
      font-family: CamphorThin;
    }
  }
`

export default TeamMemberStyle
