import styled from 'styled-components'

const TileStyle = styled.div`  
  .tile-container,
  .background-container {
    height: 100%;
    
    .grid {
      height: 100%;
    }
  }

  .background-container {
    z-index: 2;
    top: -${props => props.skewOffset}px;
    background: var(--colour-maroon);
  }
  
  .tile-container {
    z-index: 4 !important;
    top: -${props => props.skewOffset + 1}px;

    .grid {
      .tile:first-child {
        grid-row: 1;
        grid-column: 15/span 5;
        background-color: var(--colour-grey-light);
      }
      
      .tile:nth-child(2) {
        grid-row: 1;
        grid-column: viewport-start/span 4;
        background-color: var(--colour-red);
      }           
    }
  }
`

export default TileStyle