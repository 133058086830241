import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import FooterStyle from "./style"
import ContentPanel from "../content-panel/panel"
import Map from "../map/map"

const Footer = () => {
  const images = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-alternate.png" }) {
        childImageSharp {
          fixed(width: 100, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <FooterStyle className="row">
      <div className="col col-no-padding">
        <ContentPanel>
          <div className="row">
            <div className="col-12 mb-3 logo">
              <Img fixed={images.logo.childImageSharp.fixed} />
              <p className="pb-3 text-camphor-bold text-uppercase text-small">
                Commercial Steel Stud and Drywall Contractor
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-4 map">
              <Map />
            </div>

            <div className="col-8 col-md-4 contact">
              <h4>Get in touch</h4>
              <address className="mb-4">
                3200 Swansea Crescent
                <br />
                Ottawa
                <br />
                Ontario
                <br />
                K1G 3W4
              </address>
              <p className="mb-0">Tel: &nbsp;613-744-8100</p>
              <p>Fax: 613-744-8639</p>
              <p className="mb-0">Office hours:</p>
              <p className="mb-0">Monday - Friday 8am - 4.30pm</p>
            </div>

            <div className="col-12 col-md-4 pt-5 join">
              <h4>Join our team</h4>
              <p>
                {" "}
                We're always looking for dynamic, collaborative, and creative
                people to join our team.
              </p>
              <p>
                Interested in working with us? Email us with your resume at{" "}
                <a href="mailto:careers@bjnormand.com">careers@bjnormand.com</a>
              </p>

              <p className="mb-0 positions">Available positions:</p>
              <p className="mb-0 text-camphor-bold text-uppercase text-small">
                Senior Project Manager
              </p>
              <p className="mb-0 text-camphor-bold text-uppercase text-small">
                Intermediate Project Manager
              </p>
            </div>
          </div>
        </ContentPanel>
      </div>
    </FooterStyle>
  )
}

export default Footer
