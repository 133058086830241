import styled from 'styled-components'

const ClientsStyle = styled.div`
  .heading h1 {
    color: #000;
  }
    
  .clients-grid {
    display: grid;
    gap: 15px 30px;
    grid-gap: 15px 30px;
    grid-template-rows: repeat(10, 1fr);
    grid-template-columns: repeat(2, 1fr);
        
    .client {
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 65px;
      
      .gatsby-image-wrapper {
        width: 100%;
        height: auto;
        transform: scale(.8)
      } 
    }
  }  

   @media (min-width: 768px) {
    
    .clients-grid {
      gap: 50px;
      grid-gap: 50px;
      grid-template-rows: repeat(5, 1fr);
      grid-template-columns: repeat(4, 1fr);
      
      .client .gatsby-image-wrapper {
        transform: scale(1) !important;
      }    
    }
  }
`

export default ClientsStyle