import React from 'react'
import SightLinesStyle from './style'

const SightLines = () => {
  return (
    <SightLinesStyle>
        <div className="line-container">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>    
    </SightLinesStyle>
  )
};

export default SightLines