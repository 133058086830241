import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import TeamMemberStyle from './style'

const TeamMember = ({ name, title, email, image }) => (
  <TeamMemberStyle className="text-center">
    
    <div className="image-wrapper">
      <Img fluid={ image } />
    </div>

    <div className="member-details mt-2">
      <strong>{ name }</strong>
      <span>{ title }</span>
      <a href={ "mailto:" +  email }>{ email }</a>
    </div>
  
  </TeamMemberStyle>
)

TeamMember.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
  image: PropTypes.object
}

TeamMember.defaultProps = {}

export default TeamMember

