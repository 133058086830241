import styled from "styled-components"

const TeamStyle = styled.div`
  --line-colour: rgba(255, 255, 255, 0);

  position: relative;
  background: #434757;

  .heading h3,
  .heading p {
    color: #fff;
  }

  a {
    border-bottom: 4px solid rgba(255, 255, 255, 0.05);

    :hover {
      border-bottom-color: #fff;
    }
  }

  .estimating-invitations {
    margin-bottom: 4em !important;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    text-align: center;
    font-family: CamphorBold;
  }

  .second-row {
    @media (min-width: 768px) {
      margin-top: 3rem !important;
    }
  }
`

export default TeamStyle
