import styled from 'styled-components'

const SightLinesStyle = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;

    .line-container {
        z-index: 3;
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        position: relative;
        height: 100%;
        margin: 0 auto;
        pointer-events: none;
    }

    .line {
        width: 1px;
        background-size: 1px 8px;
        background-image: linear-gradient(var(--line-colour), var(--line-colour) 50%, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0));
    }
`

export default SightLinesStyle