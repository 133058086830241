export const PORTFOLIO_TYPE = {
  RETAIL: {
    KEY: "RETAIL",
    LABEL: "Retail",
  },
  GOVERNMENT: {
    KEY: "GOVERNMENT",
    LABEL: "Government",
  },
  INSTITUTIONAL: {
    KEY: "INSTITUTIONAL",
    LABEL: "Institutional",
  },
  GENERAL_CONTRACTOR: {
    KEY: "GENERAL_CONTRACTOR",
    LABEL: "General Contractor",
  },
}

export const CLIENTS = {
  AUDI: "AUDI",
  CARLETON: "CARLETON",
  OCTRANSPO: "OCTRANSPO",
  TANGER_OUTLETS: "TANGER_OUTLETS",
  STRUCTUBE: "STRUCTUBE",
  PPS: "PPS",
  HAWTHORNE: "HAWTHORNE",
  BRUYERE: "BRUYERE",
  LE_CHATEAU: "LE_CHATEAU",
  MARCH_NETWORKS: "MARCH_NETWORKS",
  NESPRESSO: "NESPRESSO"
}
