import styled from "styled-components"

const FooterStyle = styled.div`
  background: var(--colour-slate);

  .container {
    //padding-top: 4em;
    //padding-bottom: 4em;
  }

  p,
  li,
  address {
    color: rgba(255, 255, 255, 0.6);
  }

  .logo {
    p {
      color: #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  .map {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }

    ul {
      padding-left: 0;
      list-style-type: none;
    }

    li {
      & ~ li {
        margin-top: 1em;
      }
    }
  }

  .contact {
    h4 {
      font-size: 26px;
      font-weight: 400;
      font-family: "Frank Ruhl Libre", serif;
    }
    address {
      font-style: normal;
    }
  }

  .join {
    @media (min-width: 768px) {
      padding-top: 0 !important;
    }

    h4 {
      font-size: 26px;
      font-weight: 400;
      font-family: "Frank Ruhl Libre", serif;
    }
    a {
      word-break: break-word;
    }
    .positions {
      color: #fff;
    }
  }
`

export default FooterStyle
