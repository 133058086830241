import React from 'react';
import PropTypes from 'prop-types';
import ContentPanelStyle from './style';

const ContentPanel = (props) => {
  return (
    <ContentPanelStyle className="container" style={ props.style }>
      { props.children }
    </ContentPanelStyle>
  )
};


ContentPanel.propTypes = {
  style: PropTypes.object
}

ContentPanel.defaultProps = {}

export default ContentPanel

