import React from "react"
import PropTypes from "prop-types"

import TypeSelectorStyle from "./style"

const TypeSelector = props => {
  return (
    <TypeSelectorStyle className="col-6 col-sm-4 col-lg-auto type-selector text-center">
      <span
        className={props.isActive ? "active" : ""}
        onClick={() => props.onClick(props.type)}
      >
        {props.label}
      </span>
    </TypeSelectorStyle>
  )
}

TypeSelector.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
}

TypeSelector.defaultProps = {
  type: null,
}

export default TypeSelector
