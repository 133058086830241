import styled from "styled-components"

const ServicesStyle = styled.div`
  --line-colour: rgba(255, 255, 255, 0.04);

  position: relative;
  background: var(--colour-slate);

  .container {
    padding-bottom: 25em;
  }

  .service {
    .content {
      columns: 2
      column-gap: 3em;
      column-fill: balance;
      padding: 1em;
      border-radius: 5px;
      color: var(--colour-slate);
    }

    h3 {
      font-weight: 400;
      color: #fff;
      font-family: 'Frank Ruhl Libre', serif;      
      @media (min-width: 576px) {
        font-size: 1.6em;
      } 
    }

    ul {
      margin-bottom: 3em;
      padding-left: 0;
      list-style-type: none;
      
      li {
        &~li {
          padding-top: 0.75em;
        }
        color: var(--colour-grey-dark);

        @media (min-width: 768px) {
          line-height: 28px
          &~li {
            padding-top: 0.25em;
          }          
        }        
      }
    }
  }
`

export default ServicesStyle
