import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"

import PortfolioItemStyle from "./style"

const PortfolioItem = props => {
  return (
    <PortfolioItemStyle
      className="col-6 col-sm-6 col-md-4 col-lg-3 col-no-padding"
      isVisible={props.isVisible}
    >
      <div className="portfolio-item" onClick={props.onClick}>
        <BackgroundImage fluid={props.background} />
        <div>
          <span>{props.title}</span>
        </div>
      </div>
    </PortfolioItemStyle>
  )
}

PortfolioItem.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
  background: PropTypes.object,
}

PortfolioItem.defaultProps = {}

export default PortfolioItem
