import styled from "styled-components"

const AboutStyle = styled.div`
  --line-colour: rgba(0, 0, 0, 0.05);

  position: relative;
  background: var(--colour-white-dark);
  font-size: 1rem;

  .container {
    z-index: 2;
    position: relative;
    padding-bottom: 7em;
    color: var(--colour-grey-dark);
  }

  h1 {
    margin-bottom: 1em;
    font-family: CamphorThin;
  }

  .gatsby-image-wrapper {
    z-index: 4;
    margin-top: 1em;
    margin-bottom: 2em;

    @media (min-width: 992px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @media (min-width: 768px) {
    .container {
      padding-top: 2em;
    }
  }
`

export default AboutStyle
