import React from "react"
import PropTypes from "prop-types"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import AboutStyle from "./style"
import ContentPanel from "../content-panel/panel"
import Tiles from "./tiles/tiles"
import SightLines from "../sight-lines/lines"

const AboutPanel = props => {
  const image = useStaticQuery(graphql`
    query {
      mendosaFront: file(
        relativePath: { eq: "about/mendosa-photo-front.png" }
      ) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mendosaBack: file(relativePath: { eq: "about/mendosa-photo-back.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <AboutStyle className="row">
      <SightLines />

      <div className="col col-no-padding">
        <Tiles skewOffset={props.skewOffset} />

        <ContentPanel>
          <div className="row">
            <div className="col-12 col-lg-6">
              <h1 className="type-colour-red">
                {new Date().getFullYear() - 1948} Years of Excellence
              </h1>
              <p>
                B.J. Normand Limited is a fourth-generation wall and ceiling
                contractor in the Greater Ottawa and Gatineau region. With roots
                going back to 1937, BJN has evolved from a stucco and plastering
                business and transitioned with the times into a steel stud,
                drywall, office fit-ups and EIFS contractor, with a strong
                emphasis on commercial, industrial and institutional buildings.
              </p>
              <p>
                From its inception in 1948, BJ Normand Limited has built a
                reputation for its excellence, reliability, precision, and
                quality of work.
              </p>
              <p>
                We have a achieved this reputation by retaining knowledgeable
                and experienced staff, using the best products and materials
                available, performing exceptional installations at a competitive
                price and by providing the highest level of customer
                satisfaction and safety in the industry.
              </p>
              <p>
                With a significant proportion of our team being with us for over
                30 years, efficient, reliable, and safe construction projects
                are a mainstay.
              </p>
            </div>

            <div className="col-12 col-lg-6 align-self-center">
              <Img fluid={image.mendosaFront.childImageSharp.fluid} />
              <Img
                fluid={image.mendosaBack.childImageSharp.fluid}
                className="mt-4"
              />
            </div>
          </div>
        </ContentPanel>
      </div>
    </AboutStyle>
  )
}

AboutPanel.propTypes = {
  skewOffset: PropTypes.number,
}

AboutPanel.defaultProps = {}

export default AboutPanel
