import styled from 'styled-components'

const TileStyle = styled.div`

    .tile-container,
    .background-container {
      height: 100%;
    }

    .gatsby-image-wrapper {
      position: absolute;
      top: 75px;
      left: 0;
      width: 100%;
      bottom: 0;      
    }
    
    .tile-container {
      top: -${props => props.skewOffset}px;
      
      .grid {
        .tile:first-child {
          grid-row: -2;
          grid-column: -6/viewport-end;
          background-color: var(--colour-red);
        }
        .tile:nth-child(2) {
          grid-row: -2;
          grid-column: 6/span 3;
          background-color: var(--colour-grey-dark);
        }            
        .tile:nth-child(3) {
          grid-row: -4;
          grid-column: 4/span 3;
          box-shadow: inset 0 0 0 1.5px var(--colour-grey-dark);
          opacity: 0.3;
        }                    
      }
    }
`

export default TileStyle