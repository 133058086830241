import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import AssociationsStyle from "./style"
import ContentPanel from "../content-panel/panel"
import Tiles from "./tiles/tiles"
import SightLines from "../sight-lines/lines"

const AssociationsPanel = props => {
  const images = useStaticQuery(graphql`
    query {
      oca: file(relativePath: { eq: "associations/oca.png" }) {
        childImageSharp {
          fluid(maxWidth: 182, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      local2041: file(relativePath: { eq: "associations/2041.png" }) {
        childImageSharp {
          fluid(maxWidth: 187, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      local124: file(relativePath: { eq: "associations/124.png" }) {
        childImageSharp {
          fluid(maxWidth: 187, quality: 100, grayscale: true) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cca: file(relativePath: { eq: "associations/cca.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wacca: file(relativePath: { eq: "associations/wacca.png" }) {
        childImageSharp {
          fluid(maxWidth: 249, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aao: file(relativePath: { eq: "associations/aao.png" }) {
        childImageSharp {
          fluid(maxWidth: 184, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      csao: file(relativePath: { eq: "associations/csao.png" }) {
        childImageSharp {
          fluid(maxWidth: 159, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <AssociationsStyle className="row">
      <SightLines />

      <div className="col col-no-padding">
        <ContentPanel>
          <div className="row align-items-center">
            <div className="col-12 col-md-6 heading text-left">
              <h1>Associations</h1>
              <p>
                We are committed to being associated with organizations that
                represent the construction industry with the highest level of
                integrity. BJ Normand Ltd is proud to be an actively involved member
                in good standing of the following associations.
              </p>
            </div>

            <div className="col-12 col-md-6">
              <div className="row align-items-center">
                <div className="col-4 col-md-4">
                  <div className="image-wrapper" style={{ maxWidth: "130px" }}>
                    <Img fluid={images.oca.childImageSharp.fluid} />
                  </div>
                </div>

                <div className="col-4 col-md-4">
                  <div className="image-wrapper" style={{ maxWidth: "160px" }}>
                    <Img fluid={images.cca.childImageSharp.fluid} />
                  </div>
                </div>

                <div className="col-4 col-md-4">
                  <div className="image-wrapper" style={{ maxWidth: "200px" }}>
                    <Img fluid={images.wacca.childImageSharp.fluid} />
                  </div>
                </div>
              </div>

              <div className="row mt-5 align-items-center">
                <div className="col-4 col-md-4">
                  <div className="image-wrapper" style={{ maxWidth: "150px" }}>
                    <Img fluid={images.local2041.childImageSharp.fluid} />
                  </div>
                </div>

                <div className="col-4 col-md-4">
                  <div className="image-wrapper" style={{ maxWidth: "140px" }}>
                    <Img fluid={images.aao.childImageSharp.fluid} />
                  </div>
                </div>

                <div className="col-4 col-md-4">
                  <div className="image-wrapper" style={{ maxWidth: "110px" }}>
                    <Img fluid={images.local124.childImageSharp.fluid} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentPanel>
      </div>
    </AssociationsStyle>
  )
}

AssociationsPanel.propTypes = {
  skewOffset: PropTypes.number,
}

AssociationsPanel.defaultProps = {}

export default AssociationsPanel
