import styled from 'styled-components'

const PortfolioStyle = styled.div`
  --line-colour: rgba(255, 255, 255, 0.04);
  
  z-index: 5;
  position: relative;
  background: var(--colour-maroon);
  
  .background {
    z-index: 3;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;    
    background: var(--colour-maroon); // For covering the lines from the tiles background
  }

  .container {
    z-index: 5;                       // For putting content on top of the lines
    padding-top: 2em;
  }

  .heading p {
    color: var(--colour-grey-light);
  }
  
  .type-selector {
    margin-top: 1em;
  }
    
  .type-selector span {
    display: inline-block;
    padding: 0.5em 2em;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid transparent;
    
    :hover {
      border: 2px solid var(--colour-red);
    }
  }   
  
  .type-selector span.active {
    background: var(--colour-red)
  }
  
  .item-wrapper {
    margin-top: 2em;
  }

  @media (min-width: 992px) {
    .type-selector~.type-selector {
      margin-left: 2em;
    }  
  }  
`

export default PortfolioStyle