import styled from 'styled-components'

const MapStyle = styled.div`
  .google-map {
    position: relative !important;
    
    div:first-child {
      position: relative !important;
    }
  
    div, img {
      cursor: pointer !important;
    }

    a[href^="http://maps.google.com/maps"]{display:none !important}
    a[href^="https://maps.google.com/maps"]{display:none !important}
    
    .gmnoprint a, .gmnoprint span, .gm-style-cc {
      display:none;
    }
    .gmnoprint div {
      background:none !important;
    }
  }  
`

export default MapStyle