import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import TeamStyle from "./style"
import ContentPanel from "../content-panel/panel"
import TeamMemberStyle from "./team-member/team-member"
import SightLines from "../sight-lines/lines"

const TeamPanel = props => {
  const images = useStaticQuery(graphql`
    query {
      bernie: file(relativePath: { eq: "team/bernie.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      jaymee: file(relativePath: { eq: "team/jaymee.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      paul: file(relativePath: { eq: "team/paul.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      craig: file(relativePath: { eq: "team/craig.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      guy: file(relativePath: { eq: "team/guy.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <TeamStyle className="row">
      <SightLines />

      <div className="col col-no-padding">
        <ContentPanel>
          <div className="row">
            <div className="col heading">
              <h1>Our Team</h1>
              <p></p>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="estimating-invitations p-3">
                For estimating invitiations please contact{" "}
                <a href="mailto:estimating@bjnormand.com">
                  estimating@bjnormand.com
                </a>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-6 col-md-4 mb-5">
              <TeamMemberStyle
                name="Bernard M. Normand"
                title="President"
                email="bernie@bjnormand.com"
                image={images.bernie.childImageSharp.fluid}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5">
              <TeamMemberStyle
                name="Jaymee K. Normand"
                title="Vice President"
                email="jaymee@bjnormand.com"
                image={images.jaymee.childImageSharp.fluid}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5">
              <TeamMemberStyle
                name="Paul R. Normand"
                title="Senior Project Manager"
                email="paul@bjnormand.com"
                image={images.paul.childImageSharp.fluid}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5 second-row">
              <TeamMemberStyle
                name="Craig G. Szeifried"
                title="Senior Estimator"
                email="craig@bjnormand.com"
                image={images.craig.childImageSharp.fluid}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-4 mb-5 second-row">
              <TeamMemberStyle
                name="Guy E. Renaud"
                title="Controller"
                email="guy@bjnormand.com"
                image={images.guy.childImageSharp.fluid}
              />
            </div>
          </div>
        </ContentPanel>
      </div>
    </TeamStyle>
  )
}

TeamPanel.propTypes = {}

TeamPanel.defaultProps = {}

export default TeamPanel
