import React from "react"
import PropTypes from "prop-types"

import BackgroundImage from "gatsby-background-image"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import SplashStyle from "./style"
import ContentPanel from "../content-panel/panel"
import Tiles from "./tiles/tiles"

import { StaticQuery, graphql } from "gatsby"

const SplashPanel = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          audi1: file(relativePath: { eq: "slider/1-audi.jpg" }) {
            childImageSharp {
              fluid(
                maxWidth: 2007
                maxHeight: 1133
                grayscale: true
                quality: 100
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          audi2: file(relativePath: { eq: "slider/2-audi.jpg" }) {
            childImageSharp {
              fluid(
                maxWidth: 2007
                maxHeight: 1133
                grayscale: true
                quality: 100
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          carleton1: file(
            relativePath: { eq: "portfolio/carleton/gallery/02.jpg" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 2007
                maxHeight: 1133
                grayscale: true
                quality: 100
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          carleton2: file(
            relativePath: { eq: "portfolio/carleton/gallery/07.jpg" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 2007
                maxHeight: 1133
                grayscale: true
                quality: 100
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          hawthorne1: file(
            relativePath: { eq: "portfolio/hawthorne/gallery/5.jpg" }
          ) {
            childImageSharp {
              fluid(
                maxWidth: 2007
                maxHeight: 1133
                grayscale: true
                quality: 100
              ) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <SplashStyle className="row">
          <div className="col col-no-padding">
            <Tiles skewOffset={props.skewOffset} />

            <BackgroundImage fluid={data.audi2.childImageSharp.fluid} />
            <BackgroundImage fluid={data.carleton2.childImageSharp.fluid} />
            <BackgroundImage fluid={data.audi1.childImageSharp.fluid} />
            <BackgroundImage fluid={data.carleton1.childImageSharp.fluid} />

            <ContentPanel>
              <div className="row">
                <div className="col heading">
                  <h1>
                    Building Excellence for {new Date().getFullYear() - 1948}{" "}
                    Years
                  </h1>
                  <p>
                    Since 1948, BJ Normand has built a reputation of excellence,
                    reliability, precision, and quality of work.
                  </p>
                </div>
              </div>
            </ContentPanel>
          </div>
        </SplashStyle>
      )}
    />
  )
}

SplashPanel.propTypes = {
  skewOffset: PropTypes.number,
}

SplashPanel.defaultProps = {}

export default SplashPanel
